import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, utils } from 'herald-fe-shared';

import Page from '~/layout/LayoutMarketing';
import LargeCTA from '~/components/LargeCTA';
import Image from '~/components/Image';
import Opener, { USE_CASES } from '~/components/use-cases/Opener';
import UseCaseSection, {
  UseCaseScreenshot,
} from '~/components/use-cases/UseCaseSection';
import QuotesSection from '~/components/use-cases/QuotesSection';
import Silos from '~/illustrations/Silos';
import { AppSymbols } from '~/components/startups/AppsCarousel';
import { WORKS_WITH, OTHER_APPS_SLUG } from '~/components/works-with/Opener';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
  }
  .container__text {
    flex: 0 0 50%;
  }
  .channels {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
  .channels__channel {
    background: ${colors.GRAY_2(0.5)};
    padding: 1rem;
    border-radius: 5px;
  }
  .channels__channel label {
    color: ${colors.GRAY_3()};
  }
  .channels__channel__logos {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
  }
  .channels__channel__logos__logo {
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem;
  }
  .quantify {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  .quantify__screenshot__description {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-top: 1rem;
    color: ${colors.GRAY_3()};
  }
  .two-screenshots {
    margin-left: 4rem;
    position: relative;
  }
  .two-screenshots__screenshot {
    position: absolute;
  }
  .two-screenshots__screenshot--big {
    right: -2rem;
    top: -4rem;
    width: 60%;
  }
  .two-screenshots__screenshot--small {
    left: 0;
    top: 2rem;
    width: 60%;
  }
  @media screen and (max-width: 600px) {
    .quantify {
      grid-template-columns: 1fr;
    }
    .screenshots-section .section .container {
      flex-flow: column;
    }
    .screenshots-section .container__content {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: -2rem;
    }
    .two-screenshots {
      margin: 0;
    }
    .two-screenshots__screenshot--big {
      display: none;
    }
    .two-screenshots__screenshot--small {
      width: 100%;
      position: initial;
    }
  }
`;

const CHANNELS = {
  Email: [
    { id: 'front', logo: 'front-symbol', slug: WORKS_WITH.front.slug },
    { id: 'gmail', logo: 'gmail', slug: OTHER_APPS_SLUG + 'gmail' },
  ],
  Chat: [
    { id: 'intercom', logo: 'intercom', slug: WORKS_WITH.front.slug },
    { id: 'crisp', logo: 'crisp', slug: OTHER_APPS_SLUG + 'Crisp' },
    { id: 'slack', logo: 'slack', slug: WORKS_WITH.slackShared.slug },
  ],
  Text: [
    {
      id: 'openphone',
      logo: 'openphone-symbol',
      slug: OTHER_APPS_SLUG + 'OpenPhone',
    },
  ],
  'Social Media': [
    { id: 'twitter', logo: 'twitter' },
    { id: 'discord', logo: 'discord', slug: OTHER_APPS_SLUG + 'Discord' },
  ],
};

const ConsolidateFeedback: React.FC = () => {
  return (
    <Page title={USE_CASES[0].title}>
      <Styled>
        <Opener sectionIndex={0} />
        <UseCaseSection
          gray={true}
          title={<>Consolidate feedback from&nbsp;multiple&nbsp;channels.</>}
          description={
            <p>
              Your team works hard to support users over their preferred
              channel: email, chat, text, or even a call. Our{' '}
              {utils.environment?.firefox
                ? 'Firefox Addon'
                : 'Chrome Extension'}
              , Slack app, and Front plugin allow you to collect customer
              feedback from any and all channels in just one click.
            </p>
          }
          contentStyle={{ maxWidth: '45rem', height: 'auto' }}
          content={
            <>
              <div className="channels">
                {Object.keys(CHANNELS).map((c: any) => (
                  <div className="channels__channel" key={c}>
                    <label>{c}</label>
                    <div className="channels__channel__logos">
                      {CHANNELS[c].map((l: any) => {
                        const logo = (
                          <div
                            key={l.id}
                            className="channels__channel__logos__logo"
                          >
                            <Image src={`logos/${l.logo}.png`} />
                          </div>
                        );
                        return l.slug ? (
                          <Link to={`/works-with/${l.slug}`} key={l.id}>
                            {logo}
                          </Link>
                        ) : (
                          logo
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
              <AppSymbols />
            </>
          }
        />
        <div className="screenshots-section">
          <UseCaseSection
            orientation="right"
            title={<>Precisely recall a customer's&nbsp;every word.</>}
            description={
              <>
                <p>
                  Easily see the history of feedback from any given customer.
                  Our dashboards even show what portion of a customer's feedback
                  has already been addressed and what's still remaining.
                </p>
                <p style={{ marginTop: 16 }}>
                  Herald also supports B2B businesses, making it easy to pull up
                  feedback from all the users at a given company.
                </p>
              </>
            }
            textStyle={{ flex: '0 0 40%' }}
            contentStyle={{ maxWidth: 'none' }}
            content={
              <div className="two-screenshots">
                <UseCaseScreenshot className="two-screenshots__screenshot two-screenshots__screenshot--big">
                  <Image src="screenshots/company.png" />
                </UseCaseScreenshot>
                <UseCaseScreenshot className="two-screenshots__screenshot two-screenshots__screenshot--small">
                  <Image src="screenshots/contact-feedback.png" />
                </UseCaseScreenshot>
              </div>
            }
          />
        </div>
        <UseCaseSection
          orientation="left"
          gray={true}
          title={<>Break down silos across customer-facing&nbsp;teams.</>}
          description={
            <p>
              Members of sales, marketing, customer success, product management,
              and user research are talking to customers. Herald eliminates
              knowledge silos and prevents organizational bias by bringing
              customer insights into one place.
            </p>
          }
          content={<Silos />}
          textStyle={{ maxWidth: '32rem' }}
        />

        <QuotesSection sectionId="consolidate" />
        <LargeCTA title="Ready to never lose track of customer feedback again?" />
      </Styled>
    </Page>
  );
};

export default ConsolidateFeedback;
