import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, styles, utils } from 'herald-fe-shared';

import Image from '~/components/Image';
import { WORKS_WITH } from '~/components/works-with/Opener';

const Styled = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  
  .screenshot--video {
    position: relative;
  }
  .screenshot--video-container {
    margin-left: 2rem;
    box-shadow: ${styles.BOX_SHADOW};
    border-radiu
  }
  .screenshot--video-container video {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    margin-bottom: -10px;
  }
  .screenshot--video-container,
  .screenshot__color {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
  .screenshot__color {
    position: absolute;
    height: 100%;
    left: 0rem;
    top: 2rem;
    z-index: -1;
    border-radius: 5px;
    background: ${colors.BLUE_LIGHT(0.2)};
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    position: initial;
  }
  @media screen and (max-width: 500px) {
    .screenshot--video-container {
      margin: 0;
      width: 100%;
    }
    .screenshot__color {
      display: none;
    }
  }
`;

const AppSymbolsStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  .apps__app {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    margin-right: 1rem;
    transition: 250ms all;
    background: ${colors.GRAY_2(0.3)};
  }
  .apps__app:hover {
    background: ${colors.GRAY_2(0.7)};
  }
  .apps__app__logo {
    width: 2rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }
  .apps__app__logo .gatsby-image-wrapper {
    width: 100%;
  }
  .apps__app__logo svg {
    width: 100%;
    height: 1.2rem;
    fill: ${colors.GRAY_3()};
  }
  .apps__app__text__link {
    font-weight: bold;
    font-size: 0.7rem;
    margin: 0;
    line-height: 1rem;
    color: ${colors.PURPLE_LIGHT()};
  }
  @media screen and (max-width: 500px) {
    .apps {
      margin-top: 2rem;
    }
    .apps__app__logo {
      width: 1.5rem;
      margin-right: 0.5rem;
    }
  }
`;

export const AppSymbols: React.FC = () => {
  return (
    <AppSymbolsStyled className="apps">
      <Link to={`/works-with/${WORKS_WITH.front.slug}/`}>
        <div className="apps__app">
          <div className="apps__app__logo">
            <Image
              src="logos/front-symbol.png"
              style={{ objectFit: 'contain' }}
            />
          </div>
          <div className="apps__app__text">
            <h5 className="apps__app__text__name text-gray">
              Front <span className="drop-at-med">Plugin</span>
            </h5>
            <p className="apps__app__text__link">Learn More</p>
          </div>
        </div>
      </Link>

      {utils.environment?.firefox ? (
        <Link to={`/works-with/${WORKS_WITH.everything.slug}/`}>
          <div className="apps__app">
            <div className="apps__app__logo">
              <Image src="logos/firefox.png" style={{ objectFit: 'contain' }} />
            </div>
            <div className="apps__app__text">
              <h5 className="apps__app__text__name text-gray">
                Firefox <span className="drop-at-med">Addon</span>
              </h5>
              <p className="apps__app__text__link">Learn More</p>
            </div>
          </div>
        </Link>
      ) : (
        <Link to={`/works-with/${WORKS_WITH.everything.slug}/`}>
          <div className="apps__app">
            <div className="apps__app__logo">
              <Image src="logos/chrome.png" style={{ objectFit: 'contain' }} />
            </div>
            <div className="apps__app__text">
              <h5 className="apps__app__text__name text-gray">
                Chrome <span className="drop-at-med">Extension</span>
              </h5>
              <p className="apps__app__text__link">Learn More</p>
            </div>
          </div>
        </Link>
      )}

      <Link to={`/works-with/${WORKS_WITH.slackShared.slug}/`}>
        <div className="apps__app">
          <div className="apps__app__logo">
            <Image src="logos/slack.png" style={{ objectFit: 'contain' }} />
          </div>
          <div className="apps__app__text">
            <h5 className="apps__app__text__name text-gray">
              Slack <span className="drop-at-med">App</span>
            </h5>
            <p className="apps__app__text__link">Learn More</p>
          </div>
        </div>
      </Link>
    </AppSymbolsStyled>
  );
};

const AppsCarousel: React.FC = () => {
  return (
    <Styled className="container__image">
      <div className="screenshot screenshot--video">
        <div className="screenshot screenshot--video-container">
          <video autoPlay={true} loop={true} muted={true} playsInline={true}>
            <source
              src={`${process.env.GATSBY_GCP_BUCKET}/homepage/demo.0.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="screenshot__color" />
      </div>
      <AppSymbols />
    </Styled>
  );
};

export default AppsCarousel;
